<template>
  <el-form
    ref="cloneItemForm"
    :model="region"
    :rules="rules"
    label-position="top"
  >
    <el-form-item label="In welche Region kopieren?" prop="id">
      <el-select v-model="region.id" filterable placeholder="Auswählen">
        <el-option
          v-for="item in regions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <div>
      <el-button type="primary" :loading="saving" @click="checkForm">
        Kopieren
      </el-button>
      <el-button @click="closeDialog">
        Abbrechen
      </el-button>
    </div>
  </el-form>
</template>

<script>
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"

export default {
  mixins: [ApiErrorHandlerMixin],
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      regions: [],
      saving: false,
      region: {
        id: null
      },
      rules: {
        id: [
          {
            required: true,
            message: "Bitte Region auswählen",
            trigger: "change"
          }
        ]
      }
    }
  },
  async created() {
    try {
      this.regions = await RegionsRepository.getRegions()
    } catch (error) {
      this.handleApiError(error)
    }
  },
  methods: {
    closeDialog() {
      this.$emit("dialog-closed")
    },
    checkForm() {
      this.$refs["cloneItemForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        }
      })
    },
    async submitForm() {
      this.saving = true

      try {
        await GuideItemsRepository.clone(this.itemId, this.region.id)
        this.saving = false
        this.$emit("dialog-closed")
        this.$message({
          message: `Element wurde erfolgreich kopiert.`,
          type: "success",
          showClose: true
        })
      } catch (error) {
        this.handleApiError(error)
        this.saving = false
      }
    }
  }
}
</script>
